<template>
  <div class="wrapper">
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-form-input
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-button
                      type="submit"
                      variant="outline-success"
                      :disabled="saving"
                      size="sm"
                      class="mr-1"
                      @click="save"
                    >
                      <span v-if="saving">
                        <b-spinner small></b-spinner>
                        <span class="sr-only">Saving...</span>
                      </span>
                      <span v-else> <i class="far fa-save"></i> Save </span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div
                class="row settings-row"
                v-for="(section, index) in settings"
                :key="index"
              >
                <div class="col">
                  <b-alert class="d-flex justify-content-between" show>
                    <h5 class="mb-0">
                      <i :class="section.icon" v-if="section.icon"></i>
                      {{ section.name }}
                    </h5>
                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      class="caret-button"
                      @click="collapse(section.ref)"
                    >
                      <i
                        class="fas fa-angle-double-up"
                        v-if="!collapsed[section.ref]"
                      ></i>
                      <i
                        class="fas fa-angle-double-down"
                        v-if="collapsed[section.ref]"
                      ></i>
                    </b-button>
                  </b-alert>
                  <ul
                    class="list-group list-group-flush mt-3"
                    v-if="!collapsed[section.ref]"
                  >
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="(setting, index) in section.visible"
                      :key="index"
                    >
                      {{ setting.name }}
                      <b-form-checkbox
                        :checked="get(setting.target)"
                        @change="update($event, setting.target)"
                        switch
                        v-if="setting.type === 'switch'"
                      ></b-form-checkbox>
                      <div
                        class="form-group settings-form-group"
                        v-if="setting.type === 'text'"
                      >
                        <input
                          type="text"
                          class="form-control"
                          :value="get(setting.target)"
                          @input="update($event.target.value, setting.target)"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";

export default {
  name: "GeneralSettings",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader
  },
  data() {
    return {
      bind: [{ ref: "admin/config", target: "config" }],
      loading: false,
      saving: false,
      config: {
        private: {
          algolia: {},
          mailchimp: {}
        },
        public: {
          algolia: {}
        }
      },
      settings: [
        {
          name: "General",
          ref: "general",
          icon: "fas fa-cog",
          settings: [
            {
              name: "Maintenance Mode",
              target: "config.public.maintenance_mode",
              type: "switch"
            }
          ]
        },
        {
          name: "Algolia",
          ref: "algolia",
          icon: "fab fa-algolia",
          settings: [
            {
              name: "Admin Key",
              target: "config.private.algolia.admin_key",
              type: "text"
            },
            {
              name: "Private App Id",
              target: "config.private.algolia.app_id",
              type: "text"
            },
            {
              name: "Private Search Key",
              target: "config.private.algolia.search_key",
              type: "text"
            },
            {
              name: "Public App Id",
              target: "config.public.algolia.app_id",
              type: "text"
            },
            {
              name: "Public Search Key",
              target: "config.public.algolia.search_key",
              type: "text"
            }
          ]
        },
        {
          name: "MailChimp",
          ref: "mailchimp",
          icon: "fab fa-mailchimp",
          settings: [
            {
              name: "API Key",
              target: "config.private.mailchimp.api_key",
              type: "text"
            },
            {
              name: "List Id",
              target: "config.private.mailchimp.list_id",
              type: "text"
            },
            {
              name: "Server Prefix",
              target: "config.private.mailchimp.server_prefix",
              type: "text"
            }
          ]
        },
        {
          name: "Sendgrid",
          ref: "sendgrid",
          icon: "fas fa-at",
          settings: [
            {
              name: "API Key",
              target: "config.private.sendgrid_api_key",
              type: "text"
            }
          ]
        },
        {
          name: "Stripe",
          ref: "stripe",
          icon: "fab fa-stripe",
          settings: [
            {
              name: "Secret Key",
              target: "config.private.stripe_secret_key",
              type: "text"
            },
            {
              name: "Webhook Secret Key",
              target: "config.private.stripe_webhook_secret_key",
              type: "text"
            },
            {
              name: "Publishable Key",
              target: "config.public.stripe_publishable_key",
              type: "text"
            }
          ]
        }
      ],
      collapsed: {
        general: false,
        algolia: false,
        mailchimp: false,
        sendgrid: false,
        stripe: false
      },
      searchText: ""
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"])
  },
  watch: {
    searchText: {
      immediate: true,
      handler(searchText) {
        const search = searchText.toLowerCase();
        if (search.length > 0) {
          this.settings.forEach(section => {
            section.visible = section.settings.filter(setting => {
              if (setting.name.toLowerCase().includes(search)) {
                return true;
              } else {
                return false;
              }
            });
          });
        } else {
          this.settings.forEach(section => {
            section.visible = [...section.settings];
          });
        }
      }
    }
  },
  methods: {
    collapse(section) {
      this.collapsed[section] = !this.collapsed[section];
    },
    get(path, root = this) {
      if (path.includes(".")) {
        const parts = path.split(".");
        const next = parts.shift();
        return this.get(parts.join("."), root[next]);
      } else {
        return root[path];
      }
    },
    update(value, path, root = this) {
      console.log(value);
      if (path.includes(".")) {
        const parts = path.split(".");
        const next = parts.shift();
        this.update(value, parts.join("."), root[next]);
      } else {
        root[path] = value;
      }
    },
    save() {
      this.saving = true;
      const config = { ...this.config };
      this.updateObject("admin/config", null, config)
        .then(() => {
          window.toastr.success("Settings saved successfully");
          this.saving = false;
        })
        .catch(err => {
          window.toastr.error("Unable to save settings: " + err);
          console.error("Failed saving settings", err);
          this.saving = false;
        });
    }
  },
  created() {}
};
</script>

<style scoped>
.wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.settings-row {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
}

.settings-form-group {
  max-width: 450px;
  margin-right: 0;
  margin-bottom: 0;
}

.caret-button {
  padding: 0.15rem 0.5rem;
}
</style>
